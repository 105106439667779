<script lang="ts">
import { onDestroy, onMount } from 'svelte';
import SpellCard from '../SpellCard.svelte';
import { preparedSpellsByLevel, preparedSpellsCount, preparedFilter, FilterState } from '../stores/spells';
import SpellFilters from '../SpellFilters.svelte';
import LevelHeader from '../LevelHeader.svelte';
import SpellSlots from '../SpellSlots.svelte';
import Card from '../Card.svelte';
import { calc } from '../stores/gameBrain';
import Value from '../Value.svelte';

const MAX_SPELL_LEVEL: number = 9;

onMount(() => {
  $preparedFilter = FilterState.Include;
});

onDestroy(() => {
  $preparedFilter = FilterState.Off;
});

let spellsPrepared = $derived($calc('spells_prepared'));

</script>

<SpellFilters />
<div class="header">
  <section>
    {#each [...Array(MAX_SPELL_LEVEL).keys()] as level}
      <SpellSlots level={level} />
    {/each}
  </section>
  <section>
    <table>
      <tbody>
        <tr>
          <td>Spell Attack:</td>
          <td class="align-right"><Value modifier prop="spell_attack_modifier" /></td>
        </tr>
        <tr>
          <td>Spell Save DC:</td>
          <td class="align-right"><Value modifier prop="spell_save_dc" /></td>
        </tr>
        <tr>
          <td>Spellcasting ability modifier:</td>
          <td class="align-right"><Value modifier prop="spellcasting_ability_mod" /></td>
        </tr>
        <tr>
          <td>Spells Prepared:</td>
          <td class="align-right"><span class:error={$preparedSpellsCount.total > spellsPrepared}>{$preparedSpellsCount.total}</span>/<Value prop="spells_prepared" /></td>
        </tr>
      </tbody>
    </table>
  </section>
</div>

{#each Object.entries($preparedSpellsByLevel) as [level, spells]}
  <LevelHeader level={level} />
  {#each spells as spell}
    <SpellCard spell={spell} />  
  {/each}
{/each}

<style scoped>

.header {
  display: flex;
  flex-wrap: wrap;
}
</style>
