<script lang="ts">
  import { spells } from "./stores/spells";
  import { preparedSpells } from "./stores/stores";
  import Toggle from "./Toggle.svelte";
  import Entries from "./components/Entries.svelte";
  import Duration from "./components/Duration.svelte";
  import Time from "./components/Time.svelte";
  import Range from "./components/Range.svelte";
  import SpellSchool from "./SpellSchool.svelte";
  import type { Spell } from "@character-sheet/types";

  interface Props {
    spell: Spell;
  }

  let { spell }: Props = $props();
  let collapsed = $state(true);

function togglePrepared(e: { index: number | undefined, checked: boolean }) {
    preparedSpells.update((spellsPrepared) => {
      const toggled: Spell | null = e.checked ? null : $spells.find((s: Spell) => s.name === spell.name) ?? null;
      return {
        ...spellsPrepared,
        [spell.name]: toggled,
      }
    });
  }

  let prepared = $derived($preparedSpells[spell.name] || spell.additionalSpell);
</script>

<div
  class="container"
  class:expanded={!collapsed}
  class:prepared={prepared}
  >
  <div class="header">
    <button onclick={() => (collapsed = !collapsed)}>
      <h2>{spell.name}</h2>
    </button>
    {#each Object.entries(spell.components) as [component, value]}
      {#if value}<span class="component">{component}</span>{/if}
        {/each}
        {#if (spell.components.m as any)?.cost}
          <span>{(spell.components.m as any)?.cost / 100}gp</span>
        {/if}
      {#if (spell.duration[0] as any)?.concentration}<span>C</span>{/if}
        {#if spell.meta?.ritual}<span>R</span>{/if}
          {#if spell.entriesHigherLevel}<span>H</span>{/if}
            {#if spell.scalingLevelDice}<span>S</span>{/if}
              <div class="toggle">
                <Toggle
                  toggle={togglePrepared}
                  checked={!!prepared}
                  disabled={spell.additionalSpell}
                  />
              </div>
  </div>

  {#if !collapsed}
    <div class="content">
      <table>
        <tbody>
          <tr>
            <td><h4>Casting time</h4></td>
            <td><Time time={spell.time} /></td>
          </tr>
          <tr>
            <td><h4>Duration</h4></td>
            <td><Duration duration={spell.duration} /></td>
          </tr>
          <tr>
            <td><h4>Range</h4></td>
            <td><Range range={spell.range} /></td>
          </tr>
          <tr>
            <td><h4>School</h4></td>
            <td><SpellSchool school={spell.school} /></td>
          </tr>
          {#if spell.damageInflict}
          <tr>
            <td><h4>Damage inflict</h4></td>
            <td>{spell.damageInflict}</td>
          </tr>
          {/if}
          {#if spell.conditionInflict}
          <tr>
            <td><h4>Condition inflict</h4></td>
            <td>{spell.conditionInflict}</td>
          </tr>
          {/if}
        </tbody>
      </table>
      <Entries entries={spell.entries} />
      {#if spell.entriesHigherLevel}
        <Entries entries={spell.entriesHigherLevel} />
      {/if}
    </div>
  {/if}
</div>

<style scoped>
  span {
    margin-right: 5px;
  }

  table {
    margin-bottom: 1em;
  }

  .header {
    padding: 0;
    display: flex;
    height: 100%;
    padding-left: 1em;
    padding-right: 1em;
  }

  .header span {
    margin-top: 0.4em;
  }

  .container {
    margin-bottom: 1em;
    border-radius: var(--radius);
    transition: background-color var(--anim-speed);
    background-color: var(--background-dark);
  }

  .prepared {
    background-color: var(--grokange-800);
    transition: background-color var(--anim-speed);
  }

  .expanded h2 {
    font-weight: bold;
  }

  .content {
    padding: 1em;
  }

  .toggle {
    margin-left: 1em;
  }

  .component {
    color: var(--grokange-500);
  }

  h2 {
    font-size: medium;
    font-weight: normal;
    margin: 0;
  }

  h4 {
    font-size: normal;
    font-weight: bold;
    color: var(--grokange-600);
    margin: 0;
  }

  td {
    padding-right: 1em;
    text-transform: capitalize;
  }

  button {
    margin-right: 1em;
    margin-left: -1em;
    padding-left: 1em;
    width: 100%;
    height: 2em;
    text-align: left;
    background-color: transparent;
    border: none;
    user-select: none;
  }
</style>
